import slide from '../assets/penthouse/1D6A4675-min Large.jpeg'
import slide1 from '../assets/penthouse/penthouse bedroom-min Large.jpeg'
import slide2 from '../assets/penthouse/penthouse bedroom2-min Large.jpeg'
import slide3 from '../assets/penthouse/penthouse living (3)-min Large.jpeg'
import slide4 from '../assets/penthouse/penthouse living (8)-min Large.jpeg'
import slide5 from '../assets/penthouse/penthouse walk way (2)-min Large.jpeg'


const penthouse = [
    {
      category:"standard",
      src: slide
    },
    {
    category:"standard",
      src: slide1
    },
    {
      category:"penthouse",
      src: slide2
    },
    {
     category:"connecting",
      src: slide3
    },
    {
     category:"studio",
      src: slide4
    },
    {
        category:"connecting",
      src: slide5
    },
   
  ];

  export default penthouse