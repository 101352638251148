import React from 'react'
import phoneIcon from '../assets/Phone.svg'
import mailIcon from '../assets/Mail 5.svg'
import facebook from '../assets/facebook.svg'
import tiktok from '../assets/tiktok.svg'
import x from '../assets/x.svg'
import ig from '../assets/ig.svg'
import whatsapp from '../assets/whatsapp.svg'
import closeModal from '../assets/close modal.svg'
import './contact.css'
import { motion } from "framer-motion"

const contacts = (props) => {
  return (
    <div className='contact-modal'>
        <div className="modal-adress">
            <img onClick={props.openModal} src={closeModal} alt="" className="close-contact" />
            <div className="modal-phone">
              <img src={phoneIcon} alt="" />
              <a href='tel:+251 96 688 4400'>+251 96 688 4400</a>
              <a href='tel:+251 96 688 2200'>+251 96 688 2200</a>
              <a href='tel:+251 1146 2100'>+251 1146 21000</a>
            </div>
            <div className="modal-phone">
              <img src={mailIcon} alt="" />
              <p>reservation@shitayesuitehotel.com</p>
            </div>
            <div className="social">
              <p>social links</p>
              <div className="modal-links">
                  <a href="https://www.facebook.com/shitayesuitehotel/"><img src={facebook} alt="" /></a>
                  <a href="https://twitter.com/ShitayeSuite"><img src={x} alt="" /></a>
                  <a href="https://wa.me/0966884400"><img src={whatsapp} alt="" /></a>
                  <a href="https://instagram.com/shitaye_suite_hotel"><img src={ig} alt="" /></a>
                  <a href="https://instagram.com/shitaye_suite_hotel"><img src={tiktok} alt="" /></a>
              </div>
            </div>
          </div>
    </div>
  )
}

export default contacts