import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./rooms.css";
import { FreeMode, Navigation, Thumbs, Autoplay } from "swiper/modules";
import scrollIcon from "../assets/scroll down.svg";
import roomsBg from "../assets/rooms bg.png";
import "../Pages/rooms.css";
import { motion } from "framer-motion";
import bullet from "../assets/bullet.svg";
import family from "../assets/Room 1.png";
import identity from "../assets/Room 4.png";
import roomFeatures from "../assets/room features.svg";
import images from "../roomdata";
import bannerBg from "../assets/banner bg.png";
import Contacts from "../components/Contacts";


import junor from "../data/junor";
import Penthouse from "../data/pentahouse";
import Standadrd from "../data/standard";
import Identity from "../data/identity";

const Rooms = () => {
  const [modalOpened, setModalOpened] = useState(false);
  const openModal = () => {
    setModalOpened(!modalOpened);
    console.log("Contact Opened");
  };

  const [thumbsSwiper, setThumbsSwiper] = useState();
  return (
    <div className="rooms-container">
      <div className="ad-wrapper">
        <div className="ad-banner">
          <img src={bannerBg} alt="" />
          <div style={{ "--speed": `5000ms` }} className="package-name">
            <h5 className="package-title">Available packages</h5>
            <p className="package-detail">Fasting Buffet</p>
            <p className="discount">15% discount</p>
          </div>
          <div style={{ "--speed": `5000ms` }} className="package-name">
            <h5 className="package-title">Available packages</h5>
            <p className="package-detail">Fasting Buffet</p>
            <p className="discount">15% discount</p>
          </div>
          <div style={{ "--speed": `5000ms` }} className="package-name">
            <h5 className="package-title">Available packages</h5>
            <p className="package-detail">Fasting Buffet</p>
            <p className="discount">15% discount</p>
          </div>
          <div style={{ "--speed": `5000ms` }} className="package-name">
            <h5 className="package-title">Available packages</h5>
            <p className="package-detail">Fasting Buffet</p>
            <p className="discount">15% discount</p>
          </div>
          <div style={{ "--speed": `5000ms` }} className="package-name">
            <h5 className="package-title">Available packages</h5>
            <p className="package-detail">Fasting Buffet</p>
            <p className="discount">15% discount</p>
          </div>
        </div>
      </div>

      <div className="contact-btn">
        <button onClick={openModal}>Contact</button>
      </div>
      {modalOpened ? (
        <div>
          <Contacts openModal={openModal} />
        </div>
      ) : (
        ""
      )}

      <div className="rooms-hero">
        <img className="rooms-bg" src={roomsBg} alt="" />
        <div className="rooms-header">
          <h1>Rooms & Suites</h1>
          <p>
            The elegant luxury bedrooms in this gallery showcase custom interior
            designs & decorating ideas. View pictures and find your perfect
            luxury bedroom design.
          </p>
          <a href="#room-detail">
            <img src={scrollIcon} alt="" />
          </a>
        </div>
      </div>

      <motion.div
        initial={{ opacity: 0, x: "-150px", scale: 0.8 }}
        whileInView={{ opacity: 1, x: "0px", scale: 1 }}
        transition={{ duration: 0.2, type: "spring" }}
        className="alltheway"
      >
        <div id="room-detail" className="outlet-box">
          <div className="outlet-left">
            <h2>Standard Rooms</h2>
            <p>Standard Rooms</p>
            <div className="room-bar"></div>
            <ul>
              <li className="outlet-item">
                <img src={bullet} alt="shitaye hotel outlets" />4 bedroom
              </li>
              <li className="outlet-item">
                <img src={bullet} alt="shitaye hotel outlets" />3 bedroom
              </li>
              <li className="outlet-item">
                <img src={bullet} alt="shitaye hotel outlets" />
                Spacious Living Room
              </li>
              <li className="outlet-item">
                <img src={bullet} alt="shitaye hotel outlets" />
                Enormous cooking area &Appliances
              </li>
              <li className="outlet-item">
                <img src={bullet} alt="shitaye hotel outlets" />
                Counter for drinks
              </li>
            </ul>
            <div className="book-box">
              <img src={roomFeatures} alt="" />
              <a href="/" className="view-rooms-cta">
                Book Now
              </a>
            </div>
          </div>

          {/* Rooms Swiper */}

          <div className="outlet-right">
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              loop={true}
              autoplay={{
                delay: 1500,
                disableOnInteraction: false,
              }}
              spaceBetween={10}
              navigation={true}
              thumbs={{
                swiper:
                  thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
              }}
              modules={[FreeMode, Navigation, Thumbs, Autoplay]}
              className="roomSwiper2"
            >
              {Standadrd.map((image, idx) => (
                <SwiperSlide key={`slide_${idx}`} style={{ width: "100px" }}>
                  <img
                    // @note w/o unique key the image won't be updated when the image set updates.
                    key={image.src}
                    className="swiper-lazy"
                    src={image.src}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, x: "-150px", scale: 0.8 }}
        whileInView={{ opacity: 1, x: "0px", scale: 1 }}
        transition={{ duration: 0.2, type: "spring" }}
        className="alltheway"
      >
        <div id="room-detail" className="outlet-box">
          <div className="outlet-left">
            <h2>Family's first choice</h2>
            <p>The 4 bedroom penthouse</p>
            <div className="room-bar"></div>
            <ul>
              <li className="outlet-item">
                <img src={bullet} alt="shitaye hotel outlets" />4 bedroom
              </li>
              <li className="outlet-item">
                <img src={bullet} alt="shitaye hotel outlets" />3 bedroom
              </li>
              <li className="outlet-item">
                <img src={bullet} alt="shitaye hotel outlets" />
                Spacious Living Room
              </li>
              <li className="outlet-item">
                <img src={bullet} alt="shitaye hotel outlets" />
                Enormous cooking area &Appliances
              </li>
              <li className="outlet-item">
                <img src={bullet} alt="shitaye hotel outlets" />
                Counter for drinks
              </li>
            </ul>
            <div className="book-box">
              <img src={roomFeatures} alt="" />
              <a href="/" className="view-rooms-cta">
                Book Now
              </a>
            </div>
          </div>

          {/* Rooms Swiper */}

          <div className="outlet-right">
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              loop={true}
              autoplay={{
                delay: 1500,
                disableOnInteraction: false,
              }}
              spaceBetween={10}
              navigation={true}
              thumbs={{
                swiper:
                  thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
              }}
              modules={[FreeMode, Navigation, Thumbs, Autoplay]}
              className="roomSwiper2"
            >
              {Penthouse.map((image, idx) => (
                <SwiperSlide key={`slide_${idx}`} style={{ width: "100px" }}>
                  <img
                    // @note w/o unique key the image won't be updated when the image set updates.
                    key={image.src}
                    className="swiper-lazy"
                    src={image.src}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, x: "-150px", scale: 0.8 }}
        whileInView={{ opacity: 1, x: "0px", scale: 1 }}
        transition={{ duration: 0.2, type: "spring" }}
        className="alltheway"
      >
        <div id="room-detail" className="outlet-box">
          <div className="outlet-left">
            <h2>Junior Studio</h2>
            <p>Junior Studio</p>
            <div className="room-bar"></div>
            <ul>
              <li className="outlet-item">
                <img src={bullet} alt="shitaye hotel outlets" />4 bedroom
              </li>
              <li className="outlet-item">
                <img src={bullet} alt="shitaye hotel outlets" />3 bedroom
              </li>
              <li className="outlet-item">
                <img src={bullet} alt="shitaye hotel outlets" />
                Spacious Living Room
              </li>
              <li className="outlet-item">
                <img src={bullet} alt="shitaye hotel outlets" />
                Enormous cooking area &Appliances
              </li>
              <li className="outlet-item">
                <img src={bullet} alt="shitaye hotel outlets" />
                Counter for drinks
              </li>
            </ul>
            <div className="book-box">
              <img src={roomFeatures} alt="" />
              <a href="/" className="view-rooms-cta">
                Book Now
              </a>
            </div>
          </div>

          {/* Rooms Swiper */}

          <div className="outlet-right">
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              spaceBetween={10}
              navigation={true}
              thumbs={{
                swiper:
                  thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
              }}
              modules={[FreeMode, Navigation, Thumbs, Autoplay]}
              className="roomSwiper2"
            >
              {junor.map((image, idx) => (
                <SwiperSlide key={`slide_${idx}`} style={{ width: "100px" }}>
                  <img
                    // @note w/o unique key the image won't be updated when the image set updates.
                    key={image.src}
                    className="swiper-lazy"
                    src={image.src}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: "-150px", scale: 0.8 }}
        whileInView={{ opacity: 1, x: "0px", scale: 1 }}
        transition={{ duration: 0.2, type: "spring", delay: 0.3 }}
        className="alltheway"
      >
        <div className="outlet-box">
          <div className="outlet-left">
            <h2>Our Identity Rooms</h2>
            <p>Connecting rooms with option of converting to suites</p>
            <div className="room-bar"></div>
            <ul>
              <li className="outlet-item">
                <img src={bullet} alt="shitaye hotel outlets" />2 king bed rooms
                with Common living room and kitchenette
              </li>
              <li className="outlet-item">
                <img src={bullet} alt="shitaye hotel outlets" />
                Ideal for group visitor or family at least 2 per floor
              </li>
              <li className="outlet-item">
                <img src={bullet} alt="shitaye hotel outlets" />
                Each room with own Bathroom
              </li>
              <li className="outlet-item">
                <img src={bullet} alt="shitaye hotel outlets" />
                Balcony
              </li>
            </ul>
            <div className="book-box">
              <img src={roomFeatures} alt="" />
              <a href="/" className="view-rooms-cta">
                Book Now
              </a>
            </div>
          </div>
          <div className="outlet-right">
            <Swiper
              style={{
                "--swiper-navigation-color": "#fff",
                "--swiper-pagination-color": "#fff",
              }}
              spaceBetween={10}
              navigation={true}
              thumbs={{
                swiper:
                  thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
              }}
              modules={[FreeMode, Navigation, Thumbs, Autoplay]}
              className="roomSwiper2"
            >
              {Identity.map((image, idx) => (
                <SwiperSlide key={`slide_${idx}`} style={{ width: "100px" }}>
                  <img
                    // @note w/o unique key the image won't be updated when the image set updates.
                    key={image.src}
                    className="swiper-lazy"
                    src={image.src}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default Rooms;
