import React from 'react'
import logo from '../../assets/footer logo.svg'
import facebook from '../../assets/facebook.svg'
import tiktok from '../../assets/tiktok.svg'
import x from '../../assets/x.svg'
import ig from '../../assets/ig.svg'
import phoneIcon from '../../assets/Phone.svg'
import mailIcon from '../../assets/Mail 5.svg'
import '../Footer/footer.css'
import whatsapp from '../../assets/whatsapp.svg'
const Footer = () => {
  return (
    <div className='footer-container'>
        <div id='contact' className="footer-box">
            <img src={logo} alt="" />
            <div className="col-2">
                <h3>Find us and navigate
                    through our Prime location</h3>
                <p>Geo-convenient area Close to many key places of attraction& major businesses or institutions</p>
            </div>
            <div className="quick-links">
                <h3>Quick Links</h3>
                <ul>
                    <li><a href="">Room Booking</a></li>
                    <li><a href="/rooms">Rooms</a></li>
                    <li><a href="">Contact</a></li>
                    <li><a href="#outlets">Explore</a></li>
                </ul>
            </div>
            <div className="social-media">
                <h3>Social media</h3>
                <div className="social-links">
                  <a href="https://www.facebook.com/shitayesuitehotel/"><img src={facebook} alt="" /></a>
                  <a href="https://twitter.com/ShitayeSuite"><img src={x} alt="" /></a>
                  <a href="https://wa.me/0966884400"><img src={whatsapp} alt="" /></a>
                  <a href="https://instagram.com/shitaye_suite_hotel"><img src={ig} alt="" /></a>

                </div>
            </div>
            <div className="footer-adress">
                <h3>Contact us through</h3>
                <div className="f-adress">
                    <div className="phone-number">
                        <img src={phoneIcon} alt="" />
                        <p> <span>Marketing</span> 0966884400 / 0966882200 </p>
                        <p> <span>Reception</span> +251114621000</p>
                    </div>
                    <div className="phone-number">
                        <img src={mailIcon} alt="" />
                        <p>reservation@shitayesuitehotel.com</p>
                    </div>
                  </div>  
            </div>
        </div>
        <div className="footer-divider"></div>
        <div className="copyright-box">
            <p>© Copyright 2024 | Shitaye Hotel. All Rights Reserved</p>
            <p>Website Designed & Developed By <span>Hewa Technologies</span></p>
        </div>
    </div>
  )
}

export default Footer