import React, { useState } from 'react'
import Hero from '../components/Hero/Hero';
import Facilities from '../components/Facilities/Facilities';
import Luxurious from '../components/luxurious/Luxurious';
import Outlets from '../components/Outlets/Outlets';
import Meeting from '../components/Meeting Room/Meeting';
import Form from '../components/Form/Form';
import bannerBg from '../assets/banner bg.png'
import './home.css'
import Contacts from '../components/Contacts';
import Slides from '../components/Slides.jsx/Slides';
import AdBanner from './AdBanner';
const Home = () => {

  const [ modalOpened, setModalOpened ] = useState(false);
  const openModal = () =>{
    setModalOpened(!modalOpened)
    console.log("Contact Opened")
  }


  return (
    <div className='home-container'>
      {/* <div className="ad-wrapper">
        <div className="ad-banner">
          <img src={bannerBg} alt="" />
              <div style={{ "--speed": `5000ms` }} className="package-name">
                <h5 className="package-title">Available packages</h5>
                <p className="package-detail">Fasting Buffet</p>
                <p className="discount">15% discount</p>
              </div>
              <div style={{ "--speed": `5000ms` }} className="package-name">
                <h5 className="package-title">Available packages</h5>
                <p className="package-detail">Fasting Buffet</p>
                <p className="discount">15% discount</p>
              </div>
              <div style={{ "--speed": `5000ms` }} className="package-name">
                <h5 className="package-title">Available packages</h5>
                <p className="package-detail">Fasting Buffet</p>
                <p className="discount">15% discount</p>
              </div>
              <div style={{ "--speed": `5000ms` }} className="package-name">
                <h5 className="package-title">Available packages</h5>
                <p className="package-detail">Fasting Buffet</p>
                <p className="discount">15% discount</p>
              </div>
              <div style={{ "--speed": `5000ms` }} className="package-name">
                <h5 className="package-title">Available packages</h5>
                <p className="package-detail">Fasting Buffet</p>
                <p className="discount">15% discount</p>
              </div>
         </div>
      </div> */}
      <AdBanner />
      <div className="contact-btn">
        <button onClick={openModal}>Contact</button>
      </div>
      {
        modalOpened? <div>
          <Contacts openModal={openModal}/>
        </div>:
        ''
      }


      <Hero/>
      <Slides />
      {/* <Facilities /> */}
      <Luxurious />
      <Outlets />
      <Meeting />
      <Form />
    </div>
  )
}

export default Home