import React from 'react'
import '../Form/form.css'
const Form = () => {
  return (
    <div  className='form-container'>
      <h1 id='booking' >Finalize your booking request or
send us your valuable thoughts</h1>
      <form  action="#">
        <div className="left-form">
          <div className="name">
            <label htmlFor="fullname">Full Name</label>
            <input 
            type='text' 
            name='fullname' 
            id='fullname' 
            placeholder='Your Full Name' ></input>
          </div>
          <div className="message">
            <label htmlFor="message">Note</label>
            <textarea placeholder='Message' name="message" id="message" cols="30" rows="10"></textarea>
          </div>
        </div>
        <div className="right-form">
          <div className="name">
              <label htmlFor="subject">Email</label>
              <input 
              type='email' 
              name='email' 
              id='email' 
              placeholder='Enter your Email..' ></input>
          </div>
          <div className="phone">
              <label htmlFor="subject">Phone No</label>
              <input 
              type='numver' 
              name='phone' 
              id='phone' 
              placeholder='Enter your Phone number..' ></input>
          </div>
         
          <div className="radio-box">
              <h3>Addressed to</h3>
              <div className="radio-btns">
                <div className="col">
                  <input type="radio" name='adress' id='adress' />
                  <label htmlFor="meeting">Marketing</label>
                </div>
                <div className="col">
                <input type="radio" name='adress' id='adress' />
                <label htmlFor="reception">reception</label>
                </div>
                <div className="col">
                <input type="radio" name='adress' id='adress' />
                <label htmlFor="GM">GM</label>
                </div>
              </div>
              <button className='submit-cta' type='submit'>Send</button>
          </div>
          </div>
      </form>
    </div>
  )
}

export default Form