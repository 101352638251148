
import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import './styles.css';
import { FreeMode, Navigation, Thumbs, Autoplay } from 'swiper/modules';
import slide from '../../assets/slider Large.jpeg'
import slide1 from '../../assets/slider1 Large.jpeg'
import slide2 from '../../assets/slider2 Large.jpeg'
import slide3 from '../../assets/slider3 Large.jpeg'
import slide4 from '../../assets/slider4 Large.jpeg'
import slide5 from '../../assets/slider5 Large.jpeg'
import slide6 from '../../assets/slider6 Large.jpeg'
import slide7 from '../../assets/slider7 Large.jpeg'
import slide8 from '../../assets/slider8 Large.jpeg'
import slide9 from '../../assets/slider9 Large.jpeg'
import slide10 from '../../assets/slider10 Large.jpeg'
import slide11 from '../../assets/slider11 Large.jpeg'
import slide12 from '../../assets/slider12 Large.jpeg'
import slide13 from '../../assets/slider13 Large.jpeg'
import slide14 from '../../assets/slider14 Large.jpeg'

import slide16 from '../../assets/slider16 Large.jpeg'
import slide17 from '../../assets/slider17 Large.jpeg'
import slide18 from '../../assets/slider18 Large.jpeg'
import slide19 from '../../assets/slider19 Large.jpeg'
import slide20 from '../../assets/slider20 Large.jpeg'

const Slides = () => {
  const images = [
    {
      src: slide
    },
    {
      src: slide1
    },
    {
      src: slide2
    },
    {
      src: slide3
    },
    {
      src: slide4
    },
    {
      src: slide5
    },
    {
      src: slide6
    },
    {
      src: slide7
    },
    {
      src: slide8
    },
    {
      src: slide9
    },
    {
      src: slide10
    },
    {
      src: slide11
    },
    {
      src: slide12
    },
    {
      src: slide13
    },
    {
      src: slide14
    },
    {
      src: slide16
    },
    {
      src: slide17
    },
    {
      src: slide18
    },
    {
      src: slide19
    },
    {
      src: slide20
    },

  ];
    const [thumbsSwiper, setThumbsSwiper] = useState();
  return (
    <div>
        <Swiper
        style={{
          '--swiper-navigation-color': '#fff',
          '--swiper-pagination-color': '#fff',
        }}
        loop={true}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        spaceBetween={10}
        navigation={true}
        thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}}
        modules={[FreeMode, Navigation, Thumbs, Autoplay]}
        className="mySwiper2"
      >

        {images.map((image, idx) => (
          <SwiperSlide key={`slide_${idx}`} style={{ width: "100px" }}>
            <img
              // @note w/o unique key the image won't be updated when the image set updates.
              key={image.src}
              className="swiper-lazy"
              src={image.src}
            />
          </SwiperSlide>
        ))}

      </Swiper>


      
      <Swiper
      loop={true}
     
      autoplay={true}
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper"
      >
          {images.map((image, idx) => (
          <SwiperSlide key={`slide_${idx}`} style={{ width: "100px" }}>
            <img
              // @note w/o unique key the image won't be updated when the image set updates.
              key={image.src}
              className="swiper-lazy"
              src={image.src}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default Slides