import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Hero from './components/Hero/Hero';
import Facilities from './components/Facilities/Facilities';
import Luxurious from './components/luxurious/Luxurious';
import Outlets from './components/Outlets/Outlets';
import Meeting from './components/Meeting Room/Meeting';
import Form from './components/Form/Form';
import Map from './components/Map/Map';
import Footer from './components/Footer/Footer';
import Home from './Pages/Home';
import Rooms from './Pages/Rooms';
import Blogs from './Pages/Blogs';
function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/rooms' element={<Rooms />}></Route>
        <Route path='/blogs' element={<Blogs />}></Route>
      </Routes>
      <Map />
      <Footer />
    </div>
  );
}

export default App;
