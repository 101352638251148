import React from 'react'
import '../luxurious/luxurious.css'
import luxuriousBg from '../../assets/luxurious bg.png'
import standard from '../../assets/standard.jpeg'
import penthouse from '../../assets/penthouse.jpeg'
import junior from '../../assets/junior.jpeg'
import connecting from '../../assets/connecting.jpeg'
import wifi from '../../assets/tv acom.svg'
import tv from '../../assets/wifi acom.svg'
import shower from '../../assets/shower acom.svg'
import arrow from '../../assets/Arrow 2.svg'
import { motion } from 'framer-motion'
const Luxurious = () => {
    
  return (
    <div className="acom-wrapper">
        <div className="acom-container">
            <div className="col">
                <div className="row-1">
                    <img src={penthouse} alt="shitaye hotel addisababa" />
                    <h5>The 4 Bedroom penthouse</h5>
                    <div className="mini-container">
                        <div className="icons">
                            <img src={tv} alt="shitaye hotel addisababa" />
                            <img src={wifi} alt="shitaye hotel addisababa" />
                            <img src={shower} alt="shitaye hotel addisababa" />
                        </div>
                        <a href="/rooms" className="view-rooms-cta">View Rooms<img src={arrow} alt="" /></a>
                    </div>
                </div>
                <div className="row-1">
                    <img src={standard} alt="shitaye hotel addisababa" />
                    <h5>Standard rooms</h5>
                    <div className="mini-container">
                        <div className="icons">
                            <img src={tv} alt="shitaye hotel addisababa" />
                            <img src={wifi} alt="shitaye hotel addisababa" />
                            <img src={shower} alt="shitaye hotel addisababa" />
                        </div>
                        <a href="/rooms" className="view-rooms-cta">View Rooms<img src={arrow} alt="" /></a>
                    </div>
                </div>
            </div> 
            
            
            <div className="col-6">
                <h3>All Rooms</h3>
                <ul>
                    <li>B/B Fast</li>
                    <li>Shuttle</li>
                    <li>Wi-Fi /Lan</li>
                    <li>Amenities</li>
                    <li>IPTV</li>
                    <li>State of the art Kitchenette</li>
                    <li>Amazing Views</li>
                    <li>Mini Bar</li>
                    <li>Safe Boxes</li>
                    <li>Iron & Board</li>
                    <li>Private Routers</li>
                    <li>Laundry paid services</li>  
                </ul>
            </div> 

            <div className="col">
                <div className="row-1">
                        <img src={connecting} alt="shitaye hotel addisababa" />
                        <h5>Connecting Suite rooms with the option of converting to Family suites</h5>
                        <div className="mini-container">
                            <div className="icons">
                                <img src={tv} alt="shitaye hotel addisababa" />
                                <img src={wifi} alt="shitaye hotel addisababa" />
                                <img src={shower} alt="shitaye hotel addisababa" />
                            </div>
                            <a href="/rooms" className="view-rooms-cta">View Rooms<img src={arrow} alt="" /></a>
                        </div>
                    </div>
                    <div className="row-1">
                        <img src={junior} alt="shitaye hotel addisababa" />
                        <h5>The Junior Studios</h5>
                        <div className="mini-container">
                            <div className="icons">
                                <img src={tv} alt="shitaye hotel addisababa" />
                                <img src={wifi} alt="shitaye hotel addisababa" />
                                <img src={shower} alt="shitaye hotel addisababa" />
                            </div>
                            <a href="/rooms" className="view-rooms-cta">View Rooms<img src={arrow} alt="" /></a>
                        </div>
                    </div>
                </div> 
            </div>
    </div>
    // <div className='luxurious-container'>
    //     <img className='luxurious-bg' src={luxuriousBg} alt="" />
    //     <div className="luxurious-header">
    //         <motion.h1 initial={{ opacity: 0, x:"-150px", scale: 0.8 }}
    //           whileInView={{ opacity: 1, x:"0px", scale:1 }}
    //           transition={{ duration: 1, type: "spring", delay: 0.1 }}
    //           >Luxurious Rooms</motion.h1 >
    //         <div className="bar"></div>
    //         <motion.p 
    //         initial={{ opacity: 0, x:"-150px", scale: 0.8 }}
    //         whileInView={{ opacity: 1, x:"0px", scale:1 }}
    //         transition={{ duration: 1, type: "spring", delay: 0.3 }}>All room are design for your comfort</motion.p>
    //     </div>
    //     <div className="rooms">
    //         <div className="room">
    //             <motion.img 
    //             initial={{ opacity: 0, y:"150px", scale: 0.8 }}
    //             whileInView={{ opacity: 1, y:"0px", scale:1 }}
    //             transition={{ duration: 1, type: "spring", delay: 0.4 }}
    //             className='room-bg' src={room1} alt="" />
    //             <motion.div 
    //             initial={{ opacity: 0, x:"-150px", scale: 0.8 }}
    //             whileInView={{ opacity: 1, x:"0px", scale:1 }}
    //             transition={{ duration: 1, type: "spring", delay: 0.3 }} 
    //             className="room-details">
    //                 <h3>Family's first choice</h3>
    //                 <a href="/rooms" className="view-rooms-cta">View Rooms <img src={arrow} alt="" /></a>
    //             </motion.div>
    //         </div>
    //         <div className="room">
    //         <motion.img  
    //         initial={{ opacity: 0, y:"150px", scale: 0.8 }}
    //         whileInView={{ opacity: 1, y:"0px", scale:1 }}
    //         transition={{ duration: 1, type: "spring", delay: 0.4 }}
    //         className='room-bg' src={room2} alt="" />
    //             <motion.div 
    //             initial={{ opacity: 0, x:"-150px", scale: 0.8 }}
    //             whileInView={{ opacity: 1, x:"0px", scale:1 }}
    //             transition={{ duration: 1, type: "spring", delay: 0.3 }} 
    //             className="room-details">
    //                 <h3>Our Identity Rooms</h3>
    //                 <a href="/rooms" className="view-rooms-cta">View Rooms<img src={arrow} alt="" /></a>
    //             </motion.div>
    //         </div>
    //     </div>
        
    // </div>
  )
}

export default Luxurious