import React from 'react'
import blogBg from '../../assets/blog bg.png'
import './map.css'
const Map = () => {
  return (
    <div className='map-container'>
        <iframe className='map-item' src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d985.2186243877144!2d38.7660858!3d8.9836946!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOMKwNTknMDEuMCJOIDM4wrA0NSc1OS4wIkU!5e0!3m2!1sen!2set!4v1709552561343!5m2!1sen!2set" style={{width:"100%", height:"100%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>    
        <div className="blog">
          <img src={blogBg} alt="" />
          <h3>Check out our Blogs</h3>
          <button className="read-blog-cta"><a href="/blogs">View Blogs</a></button>
        </div>
</div>
  )
}

export default Map