import React from 'react'
import bannerBg from '../assets/banner bg.png'
const AdBanner = () => {
  return (
    <div className="ad-wrapper">
        <div className="ad-banner">
          <img src={bannerBg} alt="" />
              <div style={{ "--speed": `5000ms` }} className="package-name">
                <h5 className="package-title">Available packages</h5>
                <p className="package-detail">Fasting Buffet</p>
                <p className="discount">15% discount</p>
              </div>
              <div style={{ "--speed": `5000ms` }} className="package-name">
                <h5 className="package-title">Available packages</h5>
                <p className="package-detail">Fasting Buffet</p>
                <p className="discount">15% discount</p>
              </div>
              <div style={{ "--speed": `5000ms` }} className="package-name">
                <h5 className="package-title">Available packages</h5>
                <p className="package-detail">Fasting Buffet</p>
                <p className="discount">15% discount</p>
              </div>
              <div style={{ "--speed": `5000ms` }} className="package-name">
                <h5 className="package-title">Available packages</h5>
                <p className="package-detail">Fasting Buffet</p>
                <p className="discount">15% discount</p>
              </div>
              <div style={{ "--speed": `5000ms` }} className="package-name">
                <h5 className="package-title">Available packages</h5>
                <p className="package-detail">Fasting Buffet</p>
                <p className="discount">15% discount</p>
              </div>
         </div>
      </div>

  )
}

export default AdBanner