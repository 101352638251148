import React from 'react'
import '../Hero/hero.css'
import HeroImg from '../../assets/hero1.png'
import HeroMobile from '../../assets/hero1.png'
import phoneIcon from '../../assets/Phone.svg'
import mailIcon from '../../assets/Mail 5.svg'
import facebook from '../../assets/facebook.svg'
import tiktok from '../../assets/tiktok.svg'
import x from '../../assets/x.svg'
import ig from '../../assets/ig.svg'
import arrow from '../../assets/Arrow 2.svg'
import whatsapp from '../../assets/whatsapp.svg'
import { useMediaQuery } from 'react-responsive'
import playBtn from '../../assets/play btn.png'
import playDark from '../../assets/play dark.png'
import videoPlaceholder from '../../assets/hero1.png'
import { motion } from "framer-motion"
const Hero = () => {

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)'})
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)'})

  return (
    <div className="hero-wrapper">
    
    {isPortrait? 
    <div className="hero-mobile-wrapper">
      <div className="hero-mobile-container">
        <motion.img 
        initial={{ opacity: 1, x:"0px", scale: 1 }}
        whileInView={{ opacity: 1, x:"0px", scale:1.2 }}
        transition={{ duration: 1, type: "spring", delay: 0.8 }} 
        className='hero-mobile-img' src={HeroMobile} alt="" />
        
        {/* <a href="https://www.youtube.com/watch?v=oH4hH1P7vdM"><img className='play-btn' src={playBtn} alt="" /></a> */}
        <div className="hero-mobile-details">
          <motion.h1        
  initial={{ opacity: 0, x:"-150px", scale: 0.8 }}
  whileInView={{ opacity: 1, x:"0px", scale:1 }}
  transition={{ duration: 1, type: "spring", delay: 0.5 }}
            >The Unwinding Choice</motion.h1>
          <motion.p 
          initial={{ opacity: 0, x:"-150px", scale: 0.8 }}
          whileInView={{ opacity: 1, x:"0px", scale:1 }}
          transition={{ duration: 1, type: "spring", delay: 0.7 }}>Begin your journey</motion.p>
          <motion.a 
          initial={{ opacity: 0, x:"-150px", scale: 0.8 }}
          whileInView={{ opacity: 1, x:"0px", scale:1 }}
          transition={{ duration: 1, type: "spring", delay: 1 }}
          href='/rooms'
          className='click-cta'>Take a tour<img src={arrow} alt="" /></motion.a>
        </div>
        {/* <div className="video-placeholder">
        <img className='placeholder-img' src={videoPlaceholder} alt="" />
        </div> */}
        <div className="hero-mobile-right">
            <div className="video-links">
              <h1 className="watch-header">Watch</h1>
              <div className="videos">
              <a href="https://www.youtube.com/watch?v=oH4hH1P7vdM"><img className='play-icon' src={playBtn} alt="" /></a> 
              <a href="https://www.youtube.com/watch?v=oH4hH1P7vdM"><img className='play-icon' src={playBtn} alt="" /></a> 
              </div>
            </div>

          <h1 className='mobile-header'>Contact us through</h1>
          <div className="mobile-adress">
            <div className="mobile-number">
              <img src={phoneIcon} alt="" />
              <p>+251 96 688 4400 | +251 96 688 2200 | +251 1146 21000</p>
            </div>
            <div className="mobile-number">
              <img src={mailIcon} alt="" />
              <p>reservation@shitayesuitehotel.com</p>
            </div>
            <div className="social">
              <p>social links</p>
              <div className="social-links">
                  <a href="https://www.facebook.com/shitayesuitehotel/"><img src={facebook} alt="" /></a>
                  <a href="https://twitter.com/ShitayeSuite"><img src={x} alt="" /></a>
                  <a href="https://wa.me/0966884400"><img src={whatsapp} alt="" /></a>
                  <a href="https://instagram.com/shitaye_suite_hotel"><img src={ig} alt="" /></a>
                  <a href="https://instagram.com/shitaye_suite_hotel"><img src={tiktok} alt="" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div> 
    : 
    <div className='hero-container'>
      {/* <div className="video"><iframe width="900" height="350" src="https://www.youtube.com/embed/oH4hH1P7vdM?si=xEpOf-99qMBmD8u_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div> */}
        
        <div className="hero-left">
            <img className='hero-img' src={HeroImg} alt="" />
            <div className="hero-details">
              <p>Begin your journey</p>
              <button  className='click-cta'>Click Here</button>
            </div>
        </div>
     
        <div className="hero-right">
          <h1 className='hero-header'>The Unwinding Choice</h1>
          <div className="video-links">
              <h1 className="watch-header">Watch</h1>
              <div className="videos">
              <a href="https://www.youtube.com/watch?v=oH4hH1P7vdM"><img className='play-icon' src={playDark} alt="" /></a> 
              <a href="https://www.youtube.com/watch?v=oH4hH1P7vdM"><img className='play-icon' src={playDark} alt="" /></a> 
              </div>
            </div>
            <div className="right-bottom">
              <div className="tour-cta">
                <a href='/rooms'>Take a Tour <img src={arrow} alt="" /></a>
                
              </div>
              <div className="hero-adress">
                <h1>Contact us through</h1>
                <div className="phone-number">
                  <img src={phoneIcon} alt="" />
                  <p>+251 96 688 4400 | +251 96 688 2200 | +251 1146 21000</p>
                </div>
                <div className="phone-number">
                  <img src={mailIcon} alt="" />
                  <p>reservation@shitayesuitehotel.com</p>
                </div>
                <div className="social">
                  <p>social links</p>
                  <div className="social-links">
                      <a href="https://www.facebook.com/shitayesuitehotel/"><img src={facebook} alt="" /></a>
                      <a href="https://twitter.com/ShitayeSuite"><img src={x} alt="" /></a>
                      <a href="https://wa.me/0966884400"><img src={whatsapp} alt="" /></a>
                      <a href="https://instagram.com/shitaye_suite_hotel"><img src={ig} alt="" /></a>
                  </div>
                </div>
              </div>
           </div>
        </div>
    </div>
    }
    </div>
  )
}

export default Hero