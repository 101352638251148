import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import './rooms.css';
import { FreeMode, Navigation, Thumbs, Autoplay } from 'swiper/modules';
import scrollIcon from '../assets/scroll down.svg'
import blogsBg from '../assets/blogBg.jpeg'
import '../Pages/rooms.css'
import { motion } from 'framer-motion'
import bullet from '../assets/bullet.svg'
import family from '../assets/Room 1.png'
import identity from '../assets/Room 4.png'
import roomFeatures from '../assets/room features.svg'
import slide from '../assets/slider Large.jpeg'
import slide1 from '../assets/slider1 Large.jpeg'
import slide2 from '../assets/slider2 Large.jpeg'
import slide3 from '../assets/slider3 Large.jpeg'
import slide4 from '../assets/slider4 Large.jpeg'
import slide5 from '../assets/slider5 Large.jpeg'
import slide6 from '../assets/slider6 Large.jpeg'
import slide7 from '../assets/slider7 Large.jpeg'
import bannerBg from '../assets/banner bg.png'
import Contacts from '../components/Contacts';
const Blogs = () => {
  const [ modalOpened, setModalOpened ] = useState(false);
  const openModal = () =>{
    setModalOpened(!modalOpened)
    console.log("Contact Opened")
  }

    const images = [
        {
          category:"standard",
          src: slide
        },
        {
        category:"standard",
          src: slide1
        },
        {
          category:"penthouse",
          src: slide2
        },
        {
         category:"connecting",
          src: slide3
        },
        {
         category:"studio",
          src: slide4
        },
        {
            category:"connecting",
          src: slide5
        },
        {
            category:"standard",
          src: slide6
        },
        {
            category:"penthouse",
          src: slide7
        }
      ];
        const [thumbsSwiper, setThumbsSwiper] = useState();
  return (


    <div className='rooms-container'>
            <div className="ad-wrapper">
        <div className="ad-banner">
          <img src={bannerBg} alt="" />
              <div style={{ "--speed": `5000ms` }} className="package-name">
                <h5 className="package-title">Available packages</h5>
                <p className="package-detail">Fasting Buffet</p>
                <p className="discount">15% discount</p>
              </div>
              <div style={{ "--speed": `5000ms` }} className="package-name">
                <h5 className="package-title">Available packages</h5>
                <p className="package-detail">Fasting Buffet</p>
                <p className="discount">15% discount</p>
              </div>
              <div style={{ "--speed": `5000ms` }} className="package-name">
                <h5 className="package-title">Available packages</h5>
                <p className="package-detail">Fasting Buffet</p>
                <p className="discount">15% discount</p>
              </div>
              <div style={{ "--speed": `5000ms` }} className="package-name">
                <h5 className="package-title">Available packages</h5>
                <p className="package-detail">Fasting Buffet</p>
                <p className="discount">15% discount</p>
              </div>
              <div style={{ "--speed": `5000ms` }} className="package-name">
                <h5 className="package-title">Available packages</h5>
                <p className="package-detail">Fasting Buffet</p>
                <p className="discount">15% discount</p>
              </div>
         </div>
      </div>

      <div className="contact-btn">
        <button onClick={openModal}>Contact</button>
      </div>
      {
        modalOpened? <div>
          <Contacts openModal={openModal}/>
        </div>:
        ''
      }



        <div className="rooms-hero">
            <img className='rooms-bg' src={blogsBg} alt="" />
            <div className="rooms-header">
            <h1>Blogs Coming Soon</h1>
            <p>Hotel News and Events</p>
            <a href="#room-detail"><img src={scrollIcon} alt="" /></a>
        </div>
        </div>




        <div className="coming-soon" style={{height:"70vh", display: "flex", justifyContent:"center", alignItems:"center", fontSize: "40px"}}>
            Coming Soon
        </div>




            
        
    </div>
  )
}

export default Blogs