import React from 'react'
import '../Meeting Room/meeting.css'
import bullet from '../../assets/bullet.svg'
import meeting from '../../assets/meeting.png'
const Meeting = () => {
  return (
    <div className='meeting-container'>
        <div className="meeting-box">
            <img className='meeting-img' src={meeting} alt="" />
            <div className="meeting-details">
                <div className="meeting-room">
                    <h2>Serenity Meeting Room</h2>
                    <p>located on the 1st floor</p>
                    <ul>
                        <li className='outlet-item'><img src={bullet} alt="shitaye hotel outlets"/>Conduct your board, meeting, cocktail events</li>
                        <li className='outlet-item'><img src={bullet} alt="shitaye hotel outlets"/>Accommodating up to 100 pax</li>
                        <li className='outlet-item'><img src={bullet} alt="shitaye hotel outlets"/>Fully equipped with basic equipment,stationaries and buffet or tea break menus</li>
                    </ul>
                </div>
                <div className="meeting-room">
                    <h2>Fasika Board Room</h2>
                    <p>located on the 1st floor</p>
                    <ul>
                        <li className='outlet-item'><img src={bullet} alt="shitaye hotel outlets"/>Conduct your board, meeting, cocktail events</li>
                        <li className='outlet-item'><img src={bullet} alt="shitaye hotel outlets"/>Accommodating up to (25-30 pax)</li>
                        <li className='outlet-item'><img src={bullet} alt="shitaye hotel outlets"/>Fully equipped with basic equipment,stationaries and buffet or tea break menus</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Meeting