import slide from '../assets/standard/TV and cutlery-min.jpeg'
import slide1 from '../assets/standard/standard room (2)-min.jpeg'
import slide2 from '../assets/standard/standard room2-min.jpeg'
import slide3 from '../assets/standard/standard room3-min.jpeg'



const standadrd = [
    {
      category:"standard",
      src: slide
    },
    {
    category:"standard",
      src: slide1
    },
    {
      category:"penthouse",
      src: slide2
    },
    {
     category:"connecting",
      src: slide3
    },

  ];

  export default standadrd