import slide from '../assets/identity/Suite Kitchen (2)-min Large.jpeg'
import slide2 from '../assets/identity/Suite Kitchen-min Large.jpeg'
import slide3 from '../assets/identity/suite room bedroom (3)-min Large.jpeg'
import slide4 from '../assets/identity/suite room bedroom (4)-min Large.jpeg'
import slide5 from '../assets/identity/suite room living (2)-min Large.jpeg'
import slide6 from '../assets/identity/suite room living (4)-min Large.jpeg'
import slide7 from '../assets/identity/suite room living (5)-min Large.jpeg'


const identity = [
    {
      category:"standard",
      src: slide
    },
    
    {
      category:"penthouse",
      src: slide2
    },
    {
     category:"connecting",
      src: slide3
    },
    {
     category:"studio",
      src: slide4
    },
    {
        category:"connecting",
      src: slide5
    },
    {
        category:"connecting",
      src: slide6
    },
    {
        category:"connecting",
      src: slide7
    },
   
  ];

  export default identity