import slide from '../assets/junior/1D6A4513-min Large.jpeg'
import slide1 from '../assets/junior/studio copy of siute-min Large.jpeg'
import slide2 from '../assets/junior/studio copy of standard-min Large.jpeg'


const penthouse = [
    {
      category:"standard",
      src: slide
    },
    {
    category:"standard",
      src: slide1
    },
    {
      category:"penthouse",
      src: slide2
    },
   
   
  ];

  export default penthouse