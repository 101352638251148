import React from 'react'
import '../Outlets/outlets.css'
import bullet from '../../assets/bullet.svg'
import feastVille from '../../assets/restaurant.png'
import central from '../../assets/central.png'
import tabisa from '../../assets/tabisa.png'
import { motion } from 'framer-motion'
const Outlets = () => {
  return (
    <div className='outlets-container'>
        <h1 id='outlets'>Our outlets and services</h1>
        <div className="outlets-main">
            <div className="alltheway">
            <motion.div 
            initial={{ opacity: 0, x:"-150px", scale: 0.8 }}
            whileInView={{ opacity: 1, x:"0px", scale:1 }}
            transition={{ duration: .5, type: "spring", delay: 0.3 }} 
            className="outlet-box">
                <div className="outlet-left">
                    <h2>FeastVille Restaurant</h2>
                    <p>Can We be @ Your services Savor from our:</p>
                    <ul>
                        <li className='outlet-item'><img src={bullet} alt="shitaye hotel outlets"/>Full American breakfast</li>
                        <li className='outlet-item'><img src={bullet} alt="shitaye hotel outlets"/>Traditional/international menu</li>
                        <li className='outlet-item'><img src={bullet} alt="shitaye hotel outlets"/>Theme nights selection</li>
                        <li className='outlet-item'><img src={bullet} alt="shitaye hotel outlets"/>Room service menu</li>
                    </ul>
                </div>
                <div className="outlet-right">
                    <img src={feastVille} alt="" />
                </div>
            </motion.div>
            </div>
            
            <motion.div 
            initial={{ opacity: 0, x:"-150px", scale: 0.8 }}
            whileInView={{ opacity: 1, x:"0px", scale:1 }}
            transition={{ duration: .5, type: "spring", delay: 0.3 }} 
            className="outlet-box">
                <div className="outlet-left">
                    <h2>Central Cafe</h2>
                    <p>Purely urban vibes, It’s Coffee time!</p>
                    <ul>
                        <li className='outlet-item'><img src={bullet} alt="shitaye hotel outlets"/>choose your perfect rendezvous <br /> point @ the heart of the city, <br />initiate,elevate & conclude your deals</li>
                    </ul>
                </div>
                <div className="outlet-right">
                    <img src={central} alt="" />
                </div>
            </motion.div>
            <motion.div 
            initial={{ opacity: 0, x:"-150px", scale: 0.8 }}
            whileInView={{ opacity: 1, x:"0px", scale:1 }}
            transition={{ duration: .5, type: "spring", delay: 0.3 }} 
            className="alltheway">
            <div className="outlet-box">
                <div className="outlet-left">
                    <h2>TABSIA Bar</h2>
                    <p>Located on the 1st floor</p>
                    <ul>
                        <li className='outlet-item'><img src={bullet} alt="shitaye hotel outlets"/>Emancipate yourself fromHard day of work, <br />chill, refresh and taste our variety of <br /> cocktails,Spirits and many more.</li>
                    </ul>
                </div>
                <div className="outlet-right">
                    <img src={tabisa} alt="" />
                </div>
            </div>
            </motion.div>
        </div>
    </div>
  )
}

export default Outlets