import React, { useState } from 'react'
import '../Navbar/navbar.css'
import logo from '../../assets/shitaye logo.svg'
import openIcon from '../../assets/burger-menu-right-svgrepo-com.svg'
import closeIcon from '../../assets/close-circle-svgrepo-com.svg'
import { useMediaQuery } from 'react-responsive'
import { motion } from "framer-motion"
import { NavLink} from 'react-router-dom';

const Navbar = () => {
    const [ isOpen, setIsOpen ] = useState(false);
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)'})
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)'})
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)'})
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)'})
    
    const openMenu = () => {
        setIsOpen(!isOpen);
      };
      

  return (
    <div className="container">
      <NavLink to="/"><img className="logo" src={logo}></img></NavLink>
         {
          isPortrait ?  <div className={isOpen? 'nav-container mobile' : 'nav-container mobile hidden'}>
          
          <ul className='nav-links-mobile'>
     
              <motion.li 
               initial={{ opacity: 0, x:"-150px", scale: 0.8 }}
               whileInView={{ opacity: 1, x:"0px", scale:1 }}
               transition={{ duration: 0.5, type: "spring", delay: 0.2}}
               className= "nav-item"
               onClick={openMenu}><a href="/" activeClassName="nav-link-active">Home</a>
              </motion.li>
              <motion.li 
              initial={{ opacity: 0, x:"-150px", scale: 0.8 }}
  whileInView={{ opacity: 1, x:"0px", scale:1 }}
  transition={{ duration: 0.5, type: "spring", delay: 0.3 }}className='nav-item'
  onClick={openMenu}><a href="/rooms">Acommodation</a></motion.li>
              <motion.li
              initial={{ opacity: 0, x:"-150px", scale: 0.8 }}
  whileInView={{ opacity: 1, x:"0px", scale:1 }}
  transition={{ duration: 0.5, type: "spring", delay: 0.4 }} className='nav-item'
  onClick={openMenu}><a href="#outlets">Facilities</a></motion.li>
              <motion.li
              initial={{ opacity: 0, x:"-150px", scale: 0.8 }}
  whileInView={{ opacity: 1, x:"0px", scale:1 }}
  transition={{ duration: 0.5, type: "spring", delay: 0.5 }} className='nav-item'
  onClick={openMenu}><a href="#contact">Contact</a></motion.li>
              <motion.li
              initial={{ opacity: 0, x:"-150px", scale: 0.8 }}
  whileInView={{ opacity: 1, x:"0px", scale:1 }}
  transition={{ duration: 0.5, type: "spring", delay: 0.5 }} className='nav-item'
  onClick={openMenu}><a href="/blogs">Blogs</a></motion.li>
              <motion.a href='#booking'
              initial={{ opacity: 0, x:"-150px", scale: 0.8 }}
  whileInView={{ opacity: 1, x:"0px", scale:1 }}
  transition={{ duration: 0.5, type: "spring", delay: 0.6 }}className='book-cta mobile'
  onClick={openMenu}>Booking request</motion.a>
          </ul>
        
    </div> 
    :  
    <div className='nav-container'>
            
            <ul className='nav-links'>
                <a className='nav-item'href="/">Home</a>
                <a className='nav-item'href="/rooms">Acommodation</a>
                <a className='nav-item'href="#outlets">Services</a>
                <a className='nav-item'href="#contact">Contact</a>
                <a className='nav-item'href="/blogs">Blogs</a>
                <a href='#booking' className='book-cta'>Booking request</a>
            </ul>
        
    </div>
         }
    
    { isPortrait ? <img onClick={openMenu} className='toggler' src={isOpen?closeIcon : openIcon} alt=""  /> : ''}
    </div>
    
  )
  
}

export default Navbar

//  onClick={setOpenMenu(!openMenu)}